<template>
  <validation-observer
    ref="loginForm"
    #default="{invalid}"
  >

    <div>
      <h2 style="font-size: 14px; font-weight: bold">
        {{ $t('auth.login.welcome') }}
      </h2>
      <h4 style="font-size: 12px; font-weight: normal; margin-bottom: 25px">
        {{ $t('auth.login.pleaseLogin') }}
      </h4>
    </div>

    <b-form
      class="auth-login-form mt-2"
      @submit.prevent="login"
    >
      <b-form-group class="login-bottom-margin">
        <validation-provider
          #default="{ errors }"
          :name="$t('auth.form.email')"
          vid="email"
          rules="required|email"
        >
          <b-input-group
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-input-group-prepend
              is-text
            >
              <feather-icon icon="MailIcon" />
            </b-input-group-prepend>

            <b-form-input
              id="login-email"
              v-model="userEmail"
              :state="errors.length > 0 ? false:null"
              name="login-email"
              :placeholder="$t('auth.form.email')"
              class="border-left-0 p-0"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group class="login-bottom-margin">
        <div class="d-flex justify-content-between">
          <label for="login-password">&nbsp;</label>
          <b-link :to="{name:'auth-forgot-password'}">
            <small>{{ $t('auth.login.forgotPassword') }}</small>
          </b-link>
        </div>
        <validation-provider
          #default="{ errors }"
          :name="$t('auth.form.password')"
          vid="password"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-input-group-prepend
              is-text
            >
              <feather-icon icon="LockIcon" />
            </b-input-group-prepend>

            <b-form-input
              id="login-password"
              v-model="password"
              :state="errors.length > 0 ? false:null"
              class="form-control-merge"
              :type="passwordFieldType"
              name="login-password"
              :placeholder="$t('auth.form.password')"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIcon"
                class="cursor-pointer"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox
          id="remember-me"
          v-model="status"
          name="checkbox-1"
        >
          <span style="font-size: 12px">{{ $t('auth.login.remindPassword') }}</span>
        </b-form-checkbox>
      </b-form-group>

      <b-button
        type="submit"
        variant="primary"
        block
        :disabled="invalid"
      >
        {{ $t('auth.signIn') }}
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup, BFormInput, BInputGroupPrepend, BLink,
  BInputGroup, BFormCheckbox, BForm, BButton, VBTooltip, BInputGroupAppend,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  googleImg: require('@/assets/images/svg/google-color.svg'),
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              const userData = response.data
              userData.role = 'client'
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: this.$t('auth.login.loginToast.title', { username: userData.name }),
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: this.$t('auth.login.loginToast.body'),
                    },
                  })
                })
            })
            .catch(error => {
              let errorMsg = this.$t('auth.login.unknownServerResponse')
              if (error?.response?.data?.msg) { errorMsg = this.$t(error.response.data.msg) }
              this.$refs.loginForm.setErrors({ password: [errorMsg] })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss" scoped>
.login-bottom-margin {
  margin-bottom: 2rem;
}
.login-bottom-margin-super {
  margin-bottom: 5rem;
}
</style>
